import React from 'react';
import * as Styles from './PriceContainer.style';
import { Trans } from 'react-i18next';
import { UpperCaseWord } from 'assets/css/common-styles';
import { useTranslation } from 'react-i18next';
import { P } from 'components/reusable-css';

const PriceContainer = ({ data, handleSeletedPrice, values }) => {
  const { t } = useTranslation();
  return (
    <Styles.PriceCon selected={data.value == values.term} onClick={() => handleSeletedPrice(data)}>
      <P className="price">
        <Trans
          i18nKey={data.name}
          values={{
            price: data.price,
            currency: 'USD',
            currency_symbol: '$',
          }}
          components={{
            uppercase: <UpperCaseWord />,
          }}
        />
      </P>
      <P className="red">
        {data.value != 1 &&
          t('public/checkout/bm.string13', { currency_symbol: '$', price: (data.price / data.value).toFixed(2) })}
      </P>
    </Styles.PriceCon>
  );
};

export default PriceContainer;
