import styled from 'styled-components';

export const PriceCon = styled.div`
  background: white;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  border: 2px solid
    ${(props) => (props.selected ? props.theme.primaryDarkColor : props.theme.serverSizeCard.borderColor)};
  .price {
    font-weight: 600;
  }
  .red {
    color: red;
    font-style: italic;
  }
  p {
    margin: 0;
  }
  padding: 10px;
  text-align: center;
  cursor: pointer;
`;
