import React from 'react';
import * as Styles from './NewVSLastStep.style';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/custom-button';
import { scrollToSection } from 'utils';
import { P } from 'components/reusable-css';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { navLinks } from 'utils/nav-links-util';
import PriceContainer from 'pages/shoping-cart/component/price-container/PriceContainer';
import { priceArray } from '../util';

const NewVSLastStep = ({ formikProp }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const cartVs = useSelector((state) => state.vsCart.vs_deploy);
  const { validateForm, handleSubmit, isSubmitting, values, setFieldTouched, setFieldValue } = formikProp;
  let isError = values.size == '' || (values.image == '' && values.app == '');
  const handleSeletedPrice = (data) => {
    setFieldValue('term', data.value);
  };
  return (
    <Styles.NewVMLastStepSC>
      <Styles.PriceServer>
        <P className="your_server_price">{t('public/checkout/vs.string1')}</P>
        <Styles.PriceCon>
          {priceArray(values).map((data, index) => (
            <PriceContainer values={values} handleSeletedPrice={handleSeletedPrice} key={index} data={data} />
          ))}
        </Styles.PriceCon>
        {/* <Styles.Price>
            <Trans
              i18nKey="public/checkout/vs.string2"
              values={{
                price_month: priceCalculator(totalPrice(values), false),
                price_hourly: hoursTotalPrice(values).toFixed(3),
                price_yearly: yearlyTotalPrice(values).toFixed(2),
                currency: 'USD',
                currency_symbol: '$',
              }}
              components={{
                price_per_month: <Styles.PerMonth />,
                price_month: <Styles.PriceUnit />,
                price_per_hourly: <Styles.PerHour />,
                price_hourly: <span />,
                uppercase: <UpperCaseWord />,
              }}
            />
          </Styles.Price> */}
      </Styles.PriceServer>
      <Styles.VirtualServer>
        <Styles.SubmitServer>
          <CustomButton
            title={t('public/checkout/vs.string3')}
            isLoading={isSubmitting}
            type="submit"
            buttonStyles={{
              margin: '5px 5px 0 5px',
              padding: '0.8rem 1rem',
              opacity: isError && 0.3,
              backgroundColor: isError && 'gray',
            }}
            onClick={async () => {
              const errors = await validateForm();
              setFieldTouched('size', true);
              if (errors.size) {
                return scrollToSection('ST2');
              }
              if (values.image == '' && values.app == '') {
                return scrollToSection('ST4');
              }
              handleSubmit();
            }}
          />
          <CustomButton
            title={t('public/checkout/vs.string4')}
            type="submit"
            disabled={cartVs.length == 0}
            buttonStyles={{ marginLeft: '5px', marginTop: '5px', padding: '0.8rem 1rem' }}
            onClick={() => history.replace(navLinks.public_checkout_vs_summary.link)}
          />
        </Styles.SubmitServer>
      </Styles.VirtualServer>
    </Styles.NewVMLastStepSC>
  );
};

export default NewVSLastStep;
