import React from 'react';
import { NewVMStep5SC, AuthenticationSteps } from './BmCartStep5.style';
import Heading from 'components/Heading/Heading';
import { Grid, useMediaQuery } from '@material-ui/core';
import { H5, P } from 'components/reusable-css';
import Select from 'components/select';
import classes from './label-field.module.css';
import { useTranslation } from 'react-i18next';
import Loading from 'components/common/loading';

const BmCartStep5 = ({ formikProp, bmUpgrade = [], bmUpgradeLoading }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:850px)');
  const width = matches ? '100%' : '33rem';
  const { setFieldValue, values } = formikProp;
  const selectStyling = {
    width,
    marginTop: '1.7rem',
  };

  const renderUpgradeOptions = (upgradeKey) => {
    const upgradeData = bmUpgrade?.[0]?.[upgradeKey];
    if (!upgradeData) return null;

    let select_array = upgradeData.items?.map((data) => ({
      value: data.id,
      text: t(`public/checkout/bm.${data.api_language_string}`, { monthly_price: data.price_monthly_usd }),
    }));

    select_array.unshift({
      value: '',
      text: 'Please select',
    });

    const handleChange = (e) => {
      let selectedPrice = upgradeData.items?.find((data) => data.id == e.target.value);
      if (selectedPrice?.price_monthly_usd) {
        let monthly_price = selectedPrice?.price_monthly_usd || 0;
        let yearly_price = selectedPrice?.price_yearly_usd || 0;
        let bi_price = selectedPrice?.price_biennial_usd || 0;
        let tri_price = selectedPrice?.price_triennial_usd || 0;
        setFieldValue(`monthly_price.customize_server.${upgradeKey}`, {
          key: upgradeKey,
          price: monthly_price,
          name: selectedPrice?.api_language_string,
          id: selectedPrice?.id,
        });
        setFieldValue(`yearly_price.customize_server.${upgradeKey}`, {
          key: upgradeKey,
          price: yearly_price,
          name: selectedPrice?.api_language_string,
          id: selectedPrice?.id,
        });
        setFieldValue(`biennial_price.customize_server.${upgradeKey}`, {
          key: upgradeKey,
          price: bi_price,
          name: selectedPrice?.api_language_string,
          id: selectedPrice?.id,
        });
        setFieldValue(`triennial_price.customize_server.${upgradeKey}`, {
          key: upgradeKey,
          price: tri_price,
          name: selectedPrice?.api_language_string,
          id: selectedPrice?.id,
        });
      } else {
        if (values.monthly_price?.customize_server?.[upgradeKey]) {
          const updatedMonthlyCustomizeServer = { ...values.monthly_price.customize_server };
          delete updatedMonthlyCustomizeServer[upgradeKey];
          setFieldValue(`monthly_price.customize_server`, updatedMonthlyCustomizeServer);
        }
        if (values.yearly_price?.customize_server?.[upgradeKey]) {
          const updatedYearlyCustomizeServer = { ...values.yearly_price.customize_server };
          delete updatedYearlyCustomizeServer[upgradeKey];
          setFieldValue(`yearly_price.customize_server`, updatedYearlyCustomizeServer);
        }
        if (values.biennial_price?.customize_server?.[upgradeKey]) {
          const updatedBiennialCustomizeServer = { ...values.biennial_price.customize_server };
          delete updatedBiennialCustomizeServer[upgradeKey];
          setFieldValue(`biennial_price.customize_server`, updatedBiennialCustomizeServer);
        }
        if (values.triennial_price?.customize_server?.[upgradeKey]) {
          const updatedTriennialCustomizeServer = { ...values.triennial_price.customize_server };
          delete updatedTriennialCustomizeServer[upgradeKey];
          setFieldValue(`triennial_price.customize_server`, updatedTriennialCustomizeServer);
        }
      }
    };

    return (
      <Grid className={classes.main} container key={upgradeKey}>
        <Grid item xs={12} sm={5} md={4} lg={4} xl={2} className="pr-5">
          <H5 className={classes.label}>{t(`public/checkout/bm.${upgradeData.api_language_string}`)}</H5>
        </Grid>
        <Grid item xs={12} sm={7} lg={8} xl={10}>
          <Select styling={selectStyling} options={select_array} onChange={handleChange} />
        </Grid>
      </Grid>
    );
  };

  const upgradeKeys = [];
  bmUpgrade.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      upgradeKeys.push(key);
    });
  });

  return (
    <NewVMStep5SC>
      <Heading name={'public/checkout/bm.step5_string1'}>
        {bmUpgradeLoading ? (
          <Loading />
        ) : (
          <AuthenticationSteps>
            {bmUpgrade?.length > 0 ? (
              <>
                <P>{t('public/checkout/bm.step5_string3')}</P>
                {upgradeKeys.map(renderUpgradeOptions)}
              </>
            ) : (
              <P>{t('public/checkout/bm.step5_string2')}</P>
            )}
          </AuthenticationSteps>
        )}
      </Heading>
    </NewVMStep5SC>
  );
};

export default BmCartStep5;
