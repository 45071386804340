import React, { useState } from 'react';
import { NewBMSC } from './NewBM.style';
import NewBMStep1Container from './new-bm-step-1/NewBMStep1.container';
import { useQuery } from 'react-query';
import { BM_SIZES } from 'components/bm-deployment/queries';
import { reactQueryConfig } from 'constants/index';
import Loading from 'components/common/loading';
import Step2 from './new-bm-step-2/NewBMStep2.container';
import NewBMStep3Container from './new-bm-step-3/NewBMStep3.container';
import PriceCalculator from 'components/vm-deployment/price-calculator';
import classes from 'assets/css/fading-animation.module.css';
import priceCalculator from 'utils/price-calculator';
import { totalPrice } from 'components/vm-deployment/util';
import NewBMStep4Container from './new-bm-step-4/NewBMStep4.container';
import NewBMStep5Container from './new-bm-step-5/NewBMStep5.container';
import NewBMStep6Container from './new-bm-step-6/NewBMStep6.container';
import NewBMLastStep from './new-bm-last-step/NewBMLastStep.container';
import { get } from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import NewBMStep7Container from './new-bm-step-7/NewBMStep7.container';
import NewBMStep8Container from './new-bm-step-8/NewBMStep8.container';

const NewServer = ({ formikProp, loading, regionsData, isLoading }) => {
  const { setFieldValue, values } = formikProp;
  const [isShowPriceButton, setIsShowPriceButton] = useState(true);
  const handleServerSizeSelection = (data) => {
    setFieldValue('prices.size', data?.sale_price);
    setFieldValue('id', data?.id || data.size + '');
  };
  const { data: serverSizesData, isLoading: serverSideLoading } = useQuery(['BM_SIZES'], BM_SIZES, {
    ...reactQueryConfig,
    onSuccess: (res) => {
      const mostpopular = get(res, 'bm_sizes', []).find((data) => {
        let findingStock = data?.stock?.find((val) => val.region == values.region);
        let isStockAvail = findingStock?.stock > 0;
        return data.mostpopular == 1 && isStockAvail;
      });
      mostpopular != undefined && handleServerSizeSelection(mostpopular);
    },
  });
  if (serverSideLoading || isLoading) {
    return <Loading gap={200} />;
  }

  return (
    <NewBMSC>
      <div className={classes.price_box_show}>
        <PriceCalculator totalPrice={priceCalculator(totalPrice(get(formikProp, 'values.prices', 0), true) * 1)} />
      </div>
      <NewBMStep7Container regionsData={regionsData} serverSizesData={serverSizesData} formikProp={formikProp} />
      <NewBMStep1Container
        serverSizesData={serverSizesData}
        formikProp={formikProp}
        handleServerSizeSelection={handleServerSizeSelection}
        isShowPriceButton={isShowPriceButton}
      />
      <NewBMStep8Container formikProp={formikProp} />
      <Step2 formikProp={formikProp} />
      <NewBMStep3Container formikProp={formikProp} />
      <NewBMStep4Container formikProp={formikProp} />
      <NewBMStep5Container formikProp={formikProp} />
      <NewBMStep6Container formikProp={formikProp} />
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
          setIsShowPriceButton(!isVisible);
        }}
      >
        <NewBMLastStep formikProp={formikProp} loading={loading} />
      </VisibilitySensor>
    </NewBMSC>
  );
};

export default NewServer;
