import React from 'react';
import * as Styles from 'pages/shoping-cart/bm-cart/bm-cart-step2/BmCartStep2.style';
import { H5 } from 'components/reusable-css';
import ServerSizeCard from 'pages/shoping-cart/component/bm-server-size-card/ServerSizeCard';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ServerContainer = ({ heading, serverSizes, formikProp, serverType }) => {
  const { t } = useTranslation();
  const { values } = formikProp;

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '2em' }}>
        <Icon name="circle" className="circle" style={{marginTop:'0.8em'}} />
        <H5 style={{ fontWeight: 'bold' }}>{t(heading)}</H5>
      </div>
      <Styles.ServerSizeGrid>
        {serverSizes?.bm_sizes?.map((serverSize, i) => {
          return (
            serverSize.active &&
            serverSize.cpugen == serverType &&
            serverSize?.availableregions?.includes(values.region) && (
              <ServerSizeCard
                key={i}
                data={serverSize}
                selected={serverSize?.size === values?.size}
                formikProp={formikProp || {}}
              />
            )
          );
        })}
      </Styles.ServerSizeGrid>
    </>
  );
};

export default ServerContainer;
