import {
  CORE,
  DISK,
  RAM,
  RAM_DISCOUNT,
  DISK_DISCOUNT,
  CORE_DISCOUNT,
  CPU_SHARE_DISCOUNT,
  CPU_SHARE,
} from 'components/vm-deployment/constants/step1';
import priceCalculator from 'utils/price-calculator';

// const notIncludeInPricing = ['_no_discount']

export const addPricingData = (data, pricingData) => {
  return (data || []).map((item) => {
    const price = (pricingData || []).find((p) => p.id == item.id);
    return {
      ...item,
      pricing: price,
    };
  });
};

export const clean = (obj = {}) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === 0) {
      delete obj[propName];
    }
  }
  return obj;
};

const calculateTotalPrice = (obj) => {
  let totalPrice = 0;
  // Iterate through all keys in the object
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the value is an object, recursively check for 'price' or numeric values
      for (let subKey in obj[key]) {
        if (obj[key][subKey].price !== undefined) {
          if (!key?.includes('_no_discount')) {
            totalPrice += parseFloat(obj[key][subKey].price) || 0;
          } else {
            totalPrice += 0;
          }
        } else if (!isNaN(parseFloat(obj[key][subKey]))) {
          // If the value is a number, add it directly
          if (!key.includes('_no_discount')) {
            totalPrice += parseFloat(obj[key][subKey]) || 0;
          } else {
            totalPrice += 0;
          }
        }
      }
    } else if (!isNaN(parseFloat(obj[key]))) {
      // If the value is a number, add it directly
      if (!key?.includes('_no_discount')) {
        totalPrice += parseFloat(obj[key]) || 0;
      } else {
        totalPrice += 0;
      }
    }
  }
  return totalPrice;
};

export const totalPrice = (price, isbm = false, isYearly = false) => {
  if (isbm) {
    return calculateTotalPrice(price, isYearly);
  } else {
    return Object.keys(price).reduce((acc, item) => {
      if (!item?.includes('_no_discount')) {
        return acc + Number(price[item]);
      } else {
        return acc + 0;
      }
    }, 0);
  }
};

export const hoursTotalPrice = (price, isbm = false) => {
  if (isbm) {
    const total = calculateTotalPrice(price);
    return total / 672; // Assuming 672 hours in a month
  } else {
    return Object.keys(price).reduce((acc, item) => {
      if (!item?.includes('_no_discount')) {
        return acc + +price[item] / 672;
      } else {
        return acc + 0;
      }
    }, 0);
  }
};

export const vsPriceOnly = (price) => {
  return (
    Number(price.size || 0) +
    Number(price.backup_plan || 0) +
    Number(price[CORE] || 0) +
    Number(price[RAM] || 0) +
    Number(price[DISK] || 0) +
    Number(price[CPU_SHARE] || 0)
  );
};

const upgradeCalculate = (obj) => {
  let totalPrice = 0;
  let custom_server = obj.customize_server;
  // Iterate through all keys in the object
  for (let key in custom_server) {
    // If the value is an object, recursively check for 'price' or numeric values
    totalPrice += parseFloat(custom_server[key].price) || 0;
  }
  return totalPrice;
};

export const bmPriceOnly = (price, term = 1) => {
  let prices = 0;
  prices = Number(price.size) + upgradeCalculate(price);
  return prices * term;
};

export const cpuPriceOnly = (price) => {
  return (
    Number(price[CORE]) + Number(price[RAM]) + Number(price[CPU_SHARE]) + Number(price[DISK]) + Number(price.size || 0)
  );
};

export const hoursTotalPriceWithoutDiscountSlider = (price) => {
  return (
    Number(price[RAM_DISCOUNT]) / 672 +
    Number(price[DISK_DISCOUNT]) / 672 +
    Number(price[CORE_DISCOUNT]) / 672 +
    Number(price[CPU_SHARE_DISCOUNT]) / 672 +
    Number(price.backup_plan) / 672 +
    Number(price.cpanel) / 672 +
    Number(price.os) / 672 +
    Number(price.networkCapacity_no_discount || 0) / 672
  );
};

export const totalPriceWithoutDiscountSlider = (price) => {
  return (
    Number(price[RAM_DISCOUNT]) +
    Number(price[DISK_DISCOUNT]) +
    Number(price[CORE_DISCOUNT]) +
    Number(price[CPU_SHARE_DISCOUNT]) +
    Number(price.backup_plan || 0) +
    Number(price.cpanel || 0) +
    Number(price.os || 0) +
    Number(price.networkCapacity_no_discount || 0)
  );
};

export const fundCards = (data, price, noOfVM, customPrice) => {
  let items = [];
  const oldPrice = customPrice || totalPrice(price) * noOfVM;
  if (data.free_month) {
    if (data.minimum_payment == 10 && data.free_month == 0) {
      items = [
        ...items,
        {
          title: 'vs/instance/create.step7_string9',
          price: '10.00',
          options: ['vs/instance/create.step7_string13', 'vs/instance/create.step7_string14'],
        },
      ];
    }
    if (data.minimum_payment == 0 && data.free_month == 0) {
      items = [
        ...items,
        {
          title: 'vs/instance/create.step7_string10',
          price: '0',
          options: ['vs/instance/create.step7_string15', 'vs/instance/create.step7_string14'],
        },
      ];
    }
  }
  if (data.free_base_price_month) {
    if (data.price_times_months == 1) {
      items = [
        ...items,
        {
          title: 'vs/instance/create.step7_string11',
          price: priceCalculator(oldPrice, false, true),
          options: [
            'vs/instance/create.step7_string16',
            'vs/instance/create.step7_string17',
            'vs/instance/create.step7_string18',
          ],
        },
      ];
    }
    if (data.price_times_months == 12) {
      items = [
        ...items,
        {
          title: 'vs/instance/create.step7_string12',
          price: priceCalculator(oldPrice * 12, false, true),
          bonusPrice: priceCalculator(customPrice || vsPriceOnly(price) * 2, true, true),
          options: [
            'vs/instance/create.step7_string19',
            'vs/instance/create.step7_string16',
            'vs/instance/create.step7_string17',
            'vs/instance/create.step7_string20',
            'vs/instance/create.step7_string21',
            'vs/instance/create.step7_string22',
          ],
        },
      ];
    }
  }
  return items;
};
