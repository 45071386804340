import { dynamicStyles, h4Styles, pStyles } from 'components/reusable-css';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const SortBy = styled.div`
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  width: 500px;
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 310px;
    padding: 40px 10px;
  }
  @media (max-width: 330px) {
    width: 290px;
  }
`;

export const SignInContainer = styled.div`
  padding: 2em 0;
`;

export const SignInBox = styled.div`
  border-radius: 5px;
  padding: 5px 30px;
  .loader {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: -15px;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const PinSC = styled.div`
  text-align: center;
  padding-bottom: 2em;
  padding-top: 1em;
  input {
    width: 40px;
    height: 50px;
    border: 2px solid black;
    border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
    outline: none;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    border-radius: 5px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .splitter {
    padding: 0 5px;
    color: white;
    font-size: ${(props) => props.theme.default?.h4};
    ${(props) => h4Styles(props.theme.resolutions)};
  }
`;

export const Para = styled.div`
  padding: 0 12px;
  margin-top: 1em;
`;

export const Logo = styled.p`
  margin: 10px auto;
  text-align: center;
  width: 380px;
  @media (max-width: 425px) {
    width: 290px;
  }
  @media (max-width: 375px) {
    width: 250px;
  }
  @media (max-width: 375px) {
    width: 230px;
  }
`;

export const LogInButton = styled.div`
  width: auto;
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 5px;
  }
`;

export const Linked = styled.span`
  pointer-event: ${(props) => (props.loading ? 'none' : 'auto')};
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  font-size: ${(props) => props.theme.default?.p};
  margin-right: ${(props) => (props.margin ? props.margin : '0px')};
  ${(props) => pStyles(props.theme.resolutions)};
  text-align: center;
  text-decoration: underline;
  color: #ff7300;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #ff7300;
  }
`;

export const LinkSC = styled(Link)`
  pointer-event: ${(props) => (props.loading ? 'none' : 'auto')};
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  font-size: ${(props) => props.theme.default?.p};
  margin-right: ${(props) => (props.margin ? props.margin : '0px')};
  ${(props) => pStyles(props.theme.resolutions)};
  text-align: center;
  text-decoration: underline;
  color: #ff7300;
  a {
    color: #ff7300;
  }
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #ff7300;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

export const MemberNavigatorSC = styled.div`
  display: flex;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
`;

export const PageHeaderBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  max-width: calc(100vw - ${(props) => props.theme.default?.desktop_menu_width});

  ${(props) =>
    props.theme.resolutions?.map((resolution) => {
      const { width, desktop_menu_width } = resolution;
      if (desktop_menu_width !== undefined) {
        return css`
          @media only screen and (max-width: ${width}px) {
            max-width: calc(100vw - ${desktop_menu_width});
          }
        `;
      }
    })};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    max-width: 100%;
  }
`;

export const PageContainer = styled.div`
  max-height: calc(100vh - ${(props) => props.theme.default?.top_bar_height});
  width: 100%;
  overflow: ${(props) => (props.isAppDrawerOpen ? 'hidden' : 'auto')};
  position: relative;

  ${(props) =>
    props.theme.resolutions?.map((resolution) => {
      const { width, top_bar_height } = resolution;
      if (top_bar_height != undefined)
        return css`
          @media only screen and (max-width: ${width}px) {
            max-height: calc(100vh - ${top_bar_height});
          }
        `;
    })};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    max-width: 100%;
    max-height: 100%;
    margin-top: ${(props) => props.theme.default?.top_bar_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'margin-top')};
    ${(props) => props.isAppDrawerOpen && 'filter: brightness(0.95)'};
  }
`;

export const PageContent = styled.div`
  flex: 1;
  overflow-x: hidden;
  min-height: calc(100vh - ${variables.headerHeight + 100 + variables.pageContentPadding * 2}px);
  transition: transform 500ms ease;
`;

export const CartSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: 4em ${variables.pageContentPadding}px;
  padding-top: 30px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ClearAllCart = styled.div`
  display: flex;
  padding: 0.3em 0.8em;
  border-radius: 5px;
  border: 2px solid red;
  color: red;
  cursor: pointer;
`;

export const CartRow = styled.div`
  display: flex;
  margin-top: 20px;
  width: 70%;
  @media only screen and (max-width: 2100px) {
    width: 100%;
  }
  @media only screen and (max-width: 950px) {
    flex-wrap: wrap;
  }
`;

export const CartTable = styled.div`
  padding: 2em 1em;
  padding-bottom: 0;
  margin-right: 1em;
  width: 75%;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  border-radius: 8px;
  @media only screen and (max-width: 950px) {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 5px;
  }
`;

export const CartTableHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1em 0.6em 1em;
  border-bottom: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  h6 {
    text-transform: uppercase;
  }
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const CartTableContent = styled.div`
  h6 {
    margin: 0;
  }
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const CartTableContentMobile = styled.div`
  h6 {
    margin: 0;
  }
  @media only screen and (min-width: 950px) {
    display: none;
  }
`;

export const CartTableRow = styled.div`
  :last-child {
    border-bottom: 0px;
  }
  padding: 1em;
  border-bottom: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  .single_row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .bold {
    font-weight: bold;
  }
  .single_row_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const CustomServerList = styled.div`
  display: flex;
  i {
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: 8px;
    margin-top: 4px;
    margin-right: 5px;
  }
`;

export const RemoveCart = styled.div`
  padding: 0;
  width: 5%;
  text-align: right;
  cursor: pointer;
  i {
    color: red;
  }
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: 4em ${variables.pageContentPadding}px;
  width: 100%;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const PaymentMethodButton = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 2em 0;
`;

export const PaymentMethodSingleButton = styled.div`
  background-color: ${(props) => (props.selected ? props.theme.primaryDarkColor : 'white')};
  opacity: ${(props) => (props.greyOut ? 0.5 : 1)};
  pointer-event: ${(props) => (props.greyOut ? 'none' : 'auto')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  font-weight: bold;
  cursor: ${(props) => (props.greyOut ? 'default' : 'pointer')};
  border: 2px solid
    ${(props) => (props.selected ? props.theme.primaryDarkColor : props.theme.serverSizeCard.borderColor)};
`;

export const SubTotal = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1em;
`;

export const PaymentTerm = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5em 1em;
`;

export const DuePayment = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1em;
`;

export const PlaceOrder = styled.div`
  padding: 3em 3em 3em 2em;
  width: 100%;
  background: #fff;
`;

export const PlaceOrderList = styled.div`
  padding-top: 1em;
  padding-bottom: 3em;
`;

export const PlaceOrderSingle = styled.div`
  display: flex;
  h6 {
    padding-left: 5px;
  }
  i {
    margin-top: 3px;
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: 10px;
  }
`;

export const PlaceOrderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0em 3em 3em 0em;
`;

export const CartLoginScreen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4em ${variables.pageContentPadding}px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  background: #fff;
`;

export const CartAuthScreen = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

export const CartLoginForm = styled.div`
  margin-top: 30px;
  display: flex;
  .label {
    display: flex;
    flex-direction: column;
    width: 10em;
    .username {
      font-weight: bold;
      margin: 2.5em 0;
    }
    .password {
      font-weight: bold;
      margin: 1.7em 0;
    }
  }
`;

export const CartLoginFormWithEmail = styled.div`
  margin: 2em 0;
`;

export const PriceTable = styled.div`
  padding: 1em;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  border-radius: 8px;
`;

export const TotalPriceTable = styled.div`
  h5 {
    font-weight: bold;
    margin: 0;
    padding: 10px;
  }
`;
