export const RAM = 'ram';
export const DISK = 'disk';
export const CPU_SHARE = 'cpushare';
export const CORE = 'cpu';
export const RAM_DISCOUNT = 'ram_no_discount';
export const DISK_DISCOUNT = 'disk_no_discount';
export const CORE_DISCOUNT = 'cpu_no_discount';
export const CPU_SHARE_DISCOUNT = 'cpushare_no_discount';

export const createFeatures = [
  {
    id: 1,
    dataKey: 'vcpus',
    lanRef: 'cpu',
    lang: 'vs/instance/create.step1_string23',
    lang_plural: 'vs/instance/create.step1_string24',
  },
  {
    id: 2,
    dataKey: 'memory_gb',
    lanRef: 'ram',
    lang: 'vs/instance/create.step1_string19',
    lang_plural: 'vs/instance/create.step1_string20',
  },
  {
    id: 3,
    dataKey: 'disk_gb',
    lanRef: 'ssd',
    lang: 'vs/instance/create.step1_string21',
    lang_plural: 'vs/instance/create.step1_string22',
  },
  {
    id: 4,
    dataKey: 'datatransfer_gb',
    lanRef: 'data_transfer',
    lang: 'vs/instance/create.step1_string25',
    lang_plural: 'vs/instance/create.step1_string26',
  },
  {
    id: 5,
    dataKey: 'additional_bandwidth_gb_month',
    lanRef: 'additional_bandwidth_gb_month',
    lang: 'vs/instance.string208',
    lang_plural: 'vs/instance.string209',
  },
  {
    id: 6,
    dataKey: 'bandwidth_gbps',
    lanRef: 'bandwidth_gbps',
    lang: 'vs/instance/create.step1_string28',
    lang_plural: 'vs/instance/create.step1_string28',
  },
  {
    id: 7,
    dataKey: 'ipv4',
    lanRef: 'ipv4',
    lang: 'vs/instance/create.step1_string29',
    lang_plural: 'vs/instance/create.step1_string29',
  },
  {
    id: 8,
    dataKey: 'ddos_protection',
    lanRef: 'ddos_protection',
    lang: 'vs/instance/create.step1_string30',
    lang_plural: 'vs/instance/create.step1_string30',
  },
];

export const features = [
  {
    id: 1,
    data: 'XS',
  },
  {
    id: 2,
    data: 'S',
  },
  {
    id: 3,
    data: 'M',
  },
  {
    id: 4,
    data: 'L',
  },
  {
    id: 5,
    data: 'XL',
  },
  {
    id: 6,
    data: 'XXL',
  },
  {
    id: 7,
    data: 'XXXL',
  },
];

export const sliderStyles = {
  root: {
    color: '#616c7b',
    height: 6.4,
    marginTop: 5,
  },
  thumb: {
    height: 25.6,
    width: 25.6,
    backgroundColor: '#fff',
    border: '1px solid #7d7d7d',
    marginTop: -8,

    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
};

export const minOfSlider = {
  [RAM]: 512,
  [DISK]: 5,
  [CORE]: 2,
};

export const sliderValue = {
  [RAM]: {
    min: minOfSlider[RAM],
    max: 131072,
  },
  [DISK]: {
    min: minOfSlider[DISK],
    max: 2000,
  },
  [CORE]: {
    min: minOfSlider[CORE],
    max: 12,
    step: 2,
  },
};

export const sliderItemText = {
  [RAM]: 'step1_string3',
  [DISK]: 'step1_string4',
  [CORE]: 'step1_string5',
};

export const vsCategory = [
  {
    id: 'general',
    name: 'General purpose',
    lang: 'vs/instance.string80',
  },
  {
    id: 'highfrequency',
    name: 'High frequency CPU',
    lang: 'vs/instance.string81',
  },
  {
    id: 'highmemory',
    name: 'High RAM',
    lang: 'vs/instance.string82',
  },
  {
    id: 'largedisk',
    name: 'High storage',
    lang: 'vs/instance.string83',
  },
];

export const vsStep1Category = [
  {
    id: 'general',
    lang: 'step1_string9',
  },
  {
    id: 'highfrequency',
    lang: 'step1_string10',
  },
  {
    id: 'highmemory',
    lang: 'step1_string11',
  },
  {
    id: 'largedisk',
    lang: 'step1_string12',
  },
];
