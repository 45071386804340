import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep8SC = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-right: 0;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-top: 4em;
  padding-bottom: 0;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 0;
  }
`;

export const AuthenticationSteps = styled.div`
  margin: 2.5rem 0rem 4rem 0rem;
  padding-bottom: 15px;
  padding-right: 15px;
`;
