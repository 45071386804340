export const getLocalStorageValues = () => {
  let UserName = localStorage.getItem('userName');
  let lang = localStorage.getItem('i18nextLng');
  let vs_cart = localStorage.getItem('vs_cart');
  let remember_cart_login = localStorage.getItem('remember_cart_login');
  let promoCode = localStorage.getItem('promoCode');
  let domain_cart = localStorage.getItem('domain_cart');
  let bm_cart = localStorage.getItem('bm_cart');
  let remember_bm_cart_login = localStorage.getItem('remember_bm_cart_login');
  let promoBmCode = localStorage.getItem('promoBmCode');
  let bmRegion = localStorage.getItem('bmRegion');

  return {
    userName: JSON.parse(UserName) || {},
    lang,
    vs_cart: JSON.parse(vs_cart) || [],
    bm_cart: JSON.parse(bm_cart) || [],
    remember_cart_login: JSON.parse(remember_cart_login) || {},
    remember_bm_cart_login: JSON.parse(remember_bm_cart_login) || {},
    promoCode: `/${promoCode}` || '',
    promoBmCode: `/${promoBmCode}` || '',
    domain_cart: JSON.parse(domain_cart) || [],
    bmRegion,
  };
};
