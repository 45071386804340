import React from 'react';
import * as Styles from './NewVS.style';
import NewVSStep1Container from 'pages/checkout-member/vs/new-vs-step1/NewVSStep1.container';
import classes from 'assets/css/fading-animation.module.css';
import priceCalculator from 'utils/price-calculator';
import NewVSStep2Container from './new-vs-step2/NewVSStep2.container';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading/Loading';
import NewVSStep5Container from './new-vs-step5/NewVSStep5.container';
import { totalPrice, triYearlyTotalPrice, yearlyTotalPrice } from './util';
import NewVSStep3Container from './new-vs-step3/NewVSStep3.container';
import NewVSLastStepContainer from './new-vs-last-step/NewVSLastStep.container';
import AppDrawer from '../layout/app-drawer/AppDrawer';
import Header from '../layout/header/Header';
import NewVSStep4Container from './new-vs-step4/NewVSStep4.container';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navLinks } from 'utils/nav-links-util';
import PriceCalculator from 'pages/shoping-cart/component/price-calculator/PriceCalculator';

const NewVS = ({ formikProp, queryClient, isAppDrawerOpen, regionLoading, regionsData }) => {
  const locations = useLocation();
  let promocode = locations.pathname.split('public/checkout/vs/')[1];
  const { data: sizeData, isLoading: sizeLoading } = useQuery(`/public/checkout/vs/sizes/${promocode}`);
  const { data: osData, isLoading: osLoading } = useQuery('/public/checkout/vs/os');
  const { data: appsData } = useQuery('/public/checkout/vs/apps');
  const noOfVS = useSelector((state) => state.vsCart.vs_deploy);
  let history = useHistory();
  const hanldeNavigation = () => history.replace(navLinks.public_checkout_vs_summary.link);

  const isLoading = sizeLoading || regionLoading || osLoading;

  if (isLoading) {
    localStorage.setItem('promoCode', promocode);
    return <Loading gap={200} />;
  }

  return (
    <Styles.MemberNavigatorSC>
      <AppDrawer isAppDrawerOpen={isAppDrawerOpen} checkoutLink={navLinks.public_checkout_vs_summary.link} />
      <Styles.PageHeaderBodyContainer>
        <Header queryClient={queryClient} noOfCartItem={noOfVS} hanldeNavigation={hanldeNavigation} />
        <Styles.PageContainer>
          <Styles.PageContent>
            <Styles.NewVMSC>
              <div className={classes.price_box_show}>
                <PriceCalculator
                  values={formikProp.values}
                  totalPrice={priceCalculator(totalPrice(formikProp.values))}
                  yearlyTotalPrice={priceCalculator(yearlyTotalPrice(formikProp.values))}
                  triYearlyTotalPrice={priceCalculator(triYearlyTotalPrice(formikProp.values))}
                  cart_name={'vs'}
                />
              </div>
              <NewVSStep1Container sizeData={sizeData} regionsData={regionsData} formikProp={formikProp || {}} />
              <NewVSStep2Container serverSizes={sizeData} formikProp={formikProp || {}} />
              <NewVSStep3Container appsData={appsData} formikProp={formikProp || {}} />
              <NewVSStep5Container osData={osData} formikProp={formikProp || {}} />
              <NewVSStep4Container formikProp={formikProp || {}} />
              <NewVSLastStepContainer formikProp={formikProp || {}} />
            </Styles.NewVMSC>
          </Styles.PageContent>
        </Styles.PageContainer>
      </Styles.PageHeaderBodyContainer>
    </Styles.MemberNavigatorSC>
  );
};

export default NewVS;
