import React from 'react';
import { Card, GreyOut, Heading } from './RegionStep.style';
import { Grid } from '@material-ui/core';
import Checkbox from 'components/common/radio-button';
import { getRegionImage } from '../util';

const RegionStep = ({ regionsData = [], formikProp, handleScrolldown, sizeData = [], bmCart = false }) => {
  const { setFieldValue, values } = formikProp;
  return (
    <Grid container spacing={0} className="mb-5">
      {regionsData?.map((region) => {
        let region_stock_Array = sizeData?.map((data) => {
          let findRegion = data?.stock?.find((val) => val.region == region.id);
          return findRegion;
        });
        let isAnyStockAvailable = region_stock_Array?.some((val) => val?.stock > 0);
        const isGreyOut = bmCart ? region?.new_deployments_allowed == 0 : false;
        return (
          <Grid item key={region.id} xs={12} sm={6} md={4} lg={4} xl={3}>
            <GreyOut isGrey={isGreyOut || !isAnyStockAvailable}>
              <Card onClick={() => !isGreyOut && isAnyStockAvailable && handleScrolldown(region)}>
                <Checkbox
                  data={region}
                  setCheckedValue={(value) => !isGreyOut && isAnyStockAvailable && setFieldValue('region', value)}
                  checkbox={!isGreyOut && isAnyStockAvailable && values.region}
                  onClick={() => !isGreyOut && isAnyStockAvailable && handleScrolldown(region)}
                />
                <img style={{ border: '0.5px solid #C5C5C5' }} src={getRegionImage(region.country)} alt="flag Image" />
                <Heading>
                  {region.city}, {region.state}
                </Heading>
              </Card>
            </GreyOut>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default RegionStep;
