import WindowOSImage from 'assets/images/os_windows.svg';
import CentOSImage from 'assets/images/os_centos.svg';
import DebianOSImage from 'assets/images/os_debian.svg';
import FedoraOSImage from 'assets/images/os_fedora.svg';
import UbuntuOSImage from 'assets/images/os_ubuntu.svg';
import LinuxOSImage from 'assets/images/os_almalinux.svg';
import RockyOSLinux from 'assets/images/os_RockyLinux.svg';
import * as Yup from 'yup';

export const priceArray = (values) => [
  {
    name: 'public/checkout/bm.string9',
    price: totalPrice(values).toFixed(2),
    value: 1,
  },
  {
    name: 'public/checkout/bm.string10',
    price: yearlyTotalPrice(values).toFixed(2),
    value: 12,
  },
  {
    name: 'public/checkout/bm.string12',
    price: triYearlyTotalPrice(values).toFixed(2),
    value: 36,
  },
];

export const createFeatures = [
  {
    id: 1,
    dataKey: 'vcpus',
    lanRef: 'cpu',
    lang: 'public/checkout/vs.step2_string17',
    lang_plural: 'public/checkout/vs.step2_string18',
  },
  {
    id: 2,
    dataKey: 'memory_gb',
    lanRef: 'ram',
    lang: 'public/checkout/vs.step2_string13',
    lang_plural: 'public/checkout/vs.step2_string14',
  },
  {
    id: 3,
    dataKey: 'disk_gb',
    lanRef: 'ssd',
    lang: 'public/checkout/vs.step2_string15',
    lang_plural: 'public/checkout/vs.step2_string16',
  },
  {
    id: 4,
    dataKey: 'datatransfer_gb',
    lanRef: 'data_transfer',
    lang: 'public/checkout/vs.step2_string19',
    lang_plural: 'public/checkout/vs.step2_string20',
  },
  {
    id: 5,
    dataKey: 'bandwidth_gbps',
    lanRef: 'bandwidth_gbps',
    lang: 'public/checkout/vs.step2_string25',
    lang_plural: 'public/checkout/vs.step2_string25',
  },
  {
    id: 6,
    dataKey: 'ipv4',
    lanRef: 'ipv4',
    lang: 'public/checkout/vs.step2_string26',
    lang_plural: 'public/checkout/vs.step2_string26',
  },
  {
    id: 7,
    dataKey: 'private_vlan',
    lanRef: 'private_vlan',
    lang: 'public/checkout/vs.step1_string29',
    lang_plural: 'public/checkout/vs.step1_string29',
  },
  {
    id: 8,
    dataKey: 'ddos_protection',
    lanRef: 'ddos_protection',
    lang: 'public/checkout/vs.step2_string27',
    lang_plural: 'public/checkout/vs.step2_string27',
  },
];

// Recursive function to calculate the total price of any nested object
const calculateTotalPrice = (obj) => {
  let totalPrice = 0;
  // Iterate through all keys in the object
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the value is an object, recursively check for 'price' or numeric values
      for (let subKey in obj[key]) {
        if (obj[key][subKey].price !== undefined) {
          totalPrice += parseFloat(obj[key][subKey].price) || 0;
        } else if (!isNaN(parseFloat(obj[key][subKey]))) {
          // If the value is a number, add it directly
          totalPrice += parseFloat(obj[key][subKey]) || 0;
        }
      }
    } else if (!isNaN(parseFloat(obj[key]))) {
      // If the value is a number, add it directly
      totalPrice += parseFloat(obj[key]) || 0;
    }
  }

  return totalPrice;
};

// Calculate total price for monthly_price
export const totalPrice = (values) => {
  return calculateTotalPrice(values.monthly_price);
};

// Calculate total price for yearly_price
export const yearlyTotalPrice = (values) => {
  return calculateTotalPrice(values.yearly_price);
};

// Calculate total price for biennial_price
export const biYearlyTotalPrice = (values) => {
  return calculateTotalPrice(values.biennial_price);
};

// Calculate total price for triennial_price
export const triYearlyTotalPrice = (values) => {
  return calculateTotalPrice(values.triennial_price);
};

// Calculate total price for monthly_price and convert to hourly rate
export const hoursTotalPrice = (values) => {
  const total = calculateTotalPrice(values.monthly_price);
  return total / 672; // Assuming 672 hours in a month
};

export const initialValues = (regionsData = []) => {
  let cart_selected_region = regionsData.find((data) => data.default == 1);
  return {
    region: cart_selected_region?.id || '',
    size: '',
    app: '',
    image: '',
    selected_region: cart_selected_region,
    selected_size: {},
    selected_image: {},
    term: 1,
    backup: 'plan0',
    monthly_price: {
      size: 0,
      os: 0,
      app: 0,
      backup: 0,
    },
    yearly_price: {
      size: 0,
      os: 0,
      app: 0,
      backup: 0,
    },
    biennial_price: {
      size: 0,
      os: 0,
      app: 0,
      backup: 0,
    },
    triennial_price: {
      size: 0,
      os: 0,
      app: 0,
      backup: 0,
    },
  };
};

export const validationSchema = Yup.object({
  region: Yup.string().required(),
  size: Yup.string().required(),
  app: Yup.string(),
  image: Yup.string(),
  backup: Yup.string().required(),
});

export const initialValuesLogin = {
  emailAddress: '',
  selected: 100,
  password: '',
};

export const validationSchemaLogin = Yup.object({
  emailAddress: Yup.string().email('Invalid email address'),
});

export const getOSImage = (name) => {
  switch (name) {
    case 'windows':
      return WindowOSImage;
    case 'centos':
      return CentOSImage;
    case 'debian':
      return DebianOSImage;
    case 'fedora':
      return FedoraOSImage;
    case 'ubuntu':
      return UbuntuOSImage;
    case 'almalinux':
      return LinuxOSImage;
    case 'rockylinux':
      return RockyOSLinux;
    default:
      return '';
  }
};

export const osPricing = (price) =>
  Number(price?.price_monthly_usd) > 0 ? `($${Number(price?.price_monthly_usd)}/mo)` : null;
