/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { NewVMStep5SC } from 'pages/member/new-vm/new-vm-step-5/NewVMStep5.style';
import { StepDescription, ServerSizeGrid } from 'pages/member/new-vm/new-vm-step-7/NewVMStep7.style';
import PaymentCard from 'components/bm-deployment/payment-card/PaymentCard';
import VmPaymentForm from 'pages/member/new-vm/new-vm-step-7/vm-payment-form';
import { cardError } from 'validations';
import { totalPrice } from 'components/vm-deployment/util';
import _get from 'lodash/get';
import { BmHooks } from 'hooks';
import Heading from 'components/Heading/Heading';

const NewVMStep6 = ({ paymentsData, selectedServerSize, onServerSizeSelection, formikProp }) => {
  const { t } = useTranslation();
  const { isBmAmountAvailableToTotal } = BmHooks();
  // CHARGES PRICE OF BM PAYPAL
  const amountToCharge = (values) => {
    const price = Number(totalPrice(values.prices), true);
    return price;
  };
  const val = _get(paymentsData, 'prices_bm_prepaid_options[0]');
  const isFunds = isBmAmountAvailableToTotal(formikProp?.values);
  return (
    <NewVMStep5SC id="ST6">
      <Heading name={'bm/instance/create.step6_string2'}>
        {/* <StepLabel stepCount={t('bm/instance/create.step6_string1')}>
        <Trans i18nKey="bm/instance/create.step6_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel> */}
        <StepDescription>
          <Trans i18nKey="bm/instance/create.step6_string3" components={{ highlight: <Highlight /> }} />
        </StepDescription>
        <ServerSizeGrid>
          {paymentsData?.prices_bm_prepaid_options?.map(
            (paymentData) =>
              paymentData.id != 6 && (
                <PaymentCard
                  key={paymentData.id}
                  data={paymentData}
                  selected={paymentData.id === selectedServerSize.id}
                  onClick={onServerSizeSelection}
                  paymentsData={paymentsData?.prices_bm_prepaid_options || []}
                  formikProp={formikProp}
                />
              ),
          )}
        </ServerSizeGrid>
        <br />
        <br />
        <div
          style={
            val?.id === selectedServerSize?.id && val?.free_month == 0 && isFunds
              ? { opacity: '0.3', pointerEvents: 'none' }
              : {}
          }
        >
          <VmPaymentForm cardError={cardError} amount={amountToCharge(formikProp.values)} formikProp={formikProp} />
        </div>
      </Heading>
    </NewVMStep5SC>
  );
};

export default NewVMStep6;
