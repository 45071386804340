import React from 'react';
import * as Styles from 'pages/member/new-bm/new-bm-step-1/NewBMStep1.style';
import { H5 } from 'components/reusable-css';
import ServerSizeCard from 'components/bm-deployment/server-size-card';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ServerContainer = ({ heading, serverSizes, formikProp, serverType, onClick, userAccount }) => {
  const { t } = useTranslation();
  const { values } = formikProp;
  return (
    <>
      <div style={{ display: 'flex', marginBottom: '2em' }}>
        <Icon name="circle" className="para-circle" style={{ marginTop: '0.8em' }} />
        <H5 style={{ fontWeight: 'bold' }}>{t(heading)}</H5>
      </div>
      <Styles.ServerSizeGrid>
        {serverSizes?.bm_sizes?.map((serverSizeData) => {
          return (
            serverSizeData?.active &&
            serverSizeData?.availableregions?.includes(values.region) &&
            serverSizeData?.cpugen == serverType && (
              <ServerSizeCard
                key={serverSizeData.size}
                data={serverSizeData}
                selected={serverSizeData?.size === values?.id}
                onClick={onClick}
                formikProp={formikProp || {}}
                userAccount={userAccount}
              />
            )
          );
        })}
      </Styles.ServerSizeGrid>
    </>
  );
};

export default ServerContainer;
