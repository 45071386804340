export const parseManagerLevelValues = (backup_plan) => {
  switch (backup_plan) {
    case 'planlevel0':
      return 'plan0';
    case 'planlevel1':
      return 'plan1';
    case 'planlevel2':
      return 'plan2';
    default:
      break;
  }
};

export const filterBmValuesForCreation = (values, dry_run) => {
  const query = {
    region: values.region,
    label: values.virtual_server_name,
    size: values.id,
    app: values.app,
    image: values.image,
    password: btoa(values.root_pass),
    managed_plan: parseManagerLevelValues(values.managed_plan),
    ipv4_ips: values.ipv4_ips,
    options_prepaid: values.options_prepaid.id,
    dryrun: '0',
    band: '',
    ram: '',
    disk1: '',
    disk2: '',
    disk3: '',
    disk4: '',
    disk5: '',
    disk6: '',
    disk7: '',
    disk8: '',
    disk9: '',
    disk10: '',
    disk11: '',
    disk12: '',
    raid: '',
    ip: '',
    ipv6: '',
    ipbgp: '',
    raiddisk: '',
  };
  for (const key in values.prices?.customize_server) {
    const serverkey = values.prices?.customize_server[key].key;
    const serverId = values.prices?.customize_server[key].id;
    if (`${serverkey}`.includes('band')) {
      query.band = serverId;
    } else if (`${serverkey}`.includes('ram')) {
      query.ram = serverId;
    } else if (`${serverkey}`.includes('primarydisk')) {
      query.disk1 = serverId;
    } else if (`${serverkey}`.includes('disk2')) {
      query.disk2 = serverId;
    } else if (`${serverkey}`.includes('disk3')) {
      query.disk3 = serverId;
    } else if (`${serverkey}`.includes('disk4')) {
      query.disk4 = serverId;
    } else if (`${serverkey}`.includes('disk5')) {
      query.disk5 = serverId;
    } else if (`${serverkey}`.includes('disk6')) {
      query.disk6 = serverId;
    } else if (`${serverkey}`.includes('disk7')) {
      query.disk7 = serverId;
    } else if (`${serverkey}`.includes('disk8')) {
      query.disk8 = serverId;
    } else if (`${serverkey}`.includes('disk9')) {
      query.disk9 = serverId;
    } else if (`${serverkey}`.includes('disk10')) {
      query.disk10 = serverId;
    } else if (`${serverkey}`.includes('disk11')) {
      query.disk11 = serverId;
    } else if (`${serverkey}`.includes('disk12')) {
      query.disk12 = serverId;
    } else if (`${serverkey}`.includes('raiddisk')) {
      query.raiddisk = serverId;
    } else if (`${serverkey}`.includes('raid')) {
      query.raid = serverId;
    } else if (`${serverkey}`.includes('ipv6')) {
      query.ipv6 = serverId;
    } else if (`${serverkey}`.includes('ip')) {
      query.ip = serverId;
    } else if (`${serverkey}`.includes('bgp')) {
      query.ipbgp = serverId;
    }
  }
  if (dry_run) {
    query.dryrun = '1';
  }
  return query;
};
