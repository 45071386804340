import React from 'react';
import NewBmStep8 from './NewBmStep8';
import { useQuery } from 'react-query';

const NewBMStep8Container = ({ formikProp = {} }) => {
  const { data: bmUpgrade, isLoading: bmUpgradeLoading } = useQuery(`/bm/upgrades/${formikProp.values.size}`, {
    enabled: formikProp.values.size != '',
  });
  return (
    <NewBmStep8 bmUpgradeLoading={bmUpgradeLoading} bmUpgrade={bmUpgrade?.bm_upgrades} formikProp={formikProp || {}} />
  );
};

export default NewBMStep8Container;
