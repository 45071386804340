import axios from 'axios';
import { BASE_URL } from 'constants/env';

export const CHECKOUT_BM = async (e) => {
  const res = await axios.post(BASE_URL + `/public/checkout/bm`, e);
  return res.data;
};

export const CHECKOUT_BM_ID = async (e) => {
  const { id, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/public/checkout/bm/${id}`, remaining);
  return res.data;
};
