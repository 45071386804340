import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import * as Styles from './ServerSizeCard.style';
import {
  filterDataKey2,
  filterMemoryType,
  filterCount2,
  extraFeaturesBM,
  features,
  extraMegaraidFeaturesBM,
} from '../../util';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import { UpperCaseWord } from 'assets/css/common-styles';
import { HighlightBackground } from 'components/common';

const ServerSizeCard = ({ data, selected = false, formikProp }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const isSale = Number(data.price) - Number(data.sale_price);
  const { setFieldValue, values } = formikProp;
  const match = useMediaQuery('(min-width:650px)');
  const match1 = useMediaQuery('(max-width:649px)');
  const match2 = useMediaQuery('(min-width:470px)');
  const isStockAvailable = data?.stock?.find((val) => val.region == values.region);

  const handleClick = (value) => {
    if (isStockAvailable.stock == 0) {
      return;
    }
    let term = value.price_monthly_usd == '' ? 12 : 1;
    setFieldValue('term', term);
    setFieldValue('size', value.size);
    setFieldValue('monthly_price.size', value.price_monthly_usd);
    setFieldValue('yearly_price.size', value.price_yearly_usd);
    setFieldValue('biennial_price.size', value.price_biennial_usd);
    setFieldValue('triennial_price.size', value.price_triennial_usd);
    setFieldValue('selected_size', value);
    setFieldValue('app', '');
    setFieldValue('monthly_price.app', 0);
    setFieldValue('yearly_price.app', 0);
    setFieldValue('biennial_price.app', 0);
    setFieldValue('triennial_price.app', 0);
    setFieldValue(`monthly_price.customize_server`, {});
    setFieldValue(`yearly_price.customize_server`, {});
    setFieldValue(`biennial_price.customize_server`, {});
    setFieldValue(`triennial_price.customize_server`, {});
  };

  return (
    <Styles.ServerSizeCardSC
      isGrey={isStockAvailable?.stock == 0}
      data-testid="server-size-card"
      selected={selected}
      onClick={() => handleClick(data)}
    >
      {isSale ? (
        <Styles.RedTag showTag={isSale}>
          {t('bm/instance/create.step1_string9')}
          <Styles.ActualPrice>
            <Trans
              i18nKey={'bm/instance/create.step1_string11'}
              values={{
                currency_symbol: userAccount.account_currency_symbol,
                price: data.price,
              }}
            />
          </Styles.ActualPrice>
        </Styles.RedTag>
      ) : (
        <Styles.Tag showTag={Number(data.mostpopular)}>
          {Number(data.mostpopular) ? t('bm/instance/create.step1_string10') : null}
        </Styles.Tag>
      )}
      <Styles.FlexCon>
        <Styles.NameContainer>
          <Styles.Name>
            <Trans
              i18nKey={'bm/instance/create.step1_string31'}
              values={{
                cpu_socket: Number(data?.cpu_sockets) > 1 ? `x ${data?.cpu_sockets}` : '',
                cpu_model: data?.cpu_model,
              }}
            />
          </Styles.Name>
        </Styles.NameContainer>
        <Styles.Price>
          <Trans
            i18nKey="public/checkout/bm.step2_string8"
            values={{
              monthly_price: data.sale_price,
              currency: 'USD',
              currency_symbol: '$',
            }}
            components={{
              price: <Styles.PriceUnit />,
              monthly: <Styles.PerMonth />,
              currency: <Styles.Currency />,
            }}
          />
          {/* <Trans
            i18nKey={'bm/instance/create.step1_string12'}
            values={{
              currency: userAccount.account_currency,
              currency_symbol: userAccount.account_currency_symbol,
              price: data.sale_price,
            }}
            components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
          /> */}
        </Styles.Price>
      </Styles.FlexCon>

      {/* <Styles.NameContainer>
        <Styles.Name>
          <Trans
            i18nKey={'bm/instance/create.step1_string31'}
            values={{
              cpu_socket: Number(data?.cpu_sockets) > 1 ? `x ${data?.cpu_sockets}` : '',
              cpu_model: data?.cpu_model,
            }}
          />
        </Styles.Name>
      </Styles.NameContainer>
      <Styles.Price>
        <Styles.PerMonth>
          <Trans
            i18nKey={'bm/instance/create.step1_string12'}
            values={{
              currency: userAccount.account_currency,
              currency_symbol: userAccount.account_currency_symbol,
              price: data.sale_price,
            }}
            components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
          />
        </Styles.PerMonth>
      </Styles.Price> */}
      <Styles.Features>
        <Grid container>
          {features.map((feature) => (
            <Grid
              item
              key={feature.id}
              xs={match2 ? 6 : 12}
              sm={match ? 12 : match1 ? 6 : null}
              md={12}
              lg={12}
              xl={12}
            >
              <Styles.Feature key={feature.id}>
                <Icon name="check" />
                <Trans
                  i18nKey={
                    feature.dataKey2 == 'cpu_sockets'
                      ? data[feature.dataKey2] == 1
                        ? feature.langSingle
                        : data[feature.dataKey2] == 2
                        ? feature.langDual
                        : data[feature.dataKey2] == 4
                        ? feature.langQuad
                        : null
                      : data[feature.dataKey] > 1
                      ? feature.plural_lang
                      : feature.lang
                  }
                  values={{
                    count: data[feature.dataKey],
                    counts: filterDataKey2(data[feature.dataKey2], feature.lanRef),
                    count2: filterCount2(data[feature.dataKey3], feature.lanRef),
                    count3: filterMemoryType(data[feature.dataKey2], feature.lanRef),
                  }}
                />
              </Styles.Feature>
            </Grid>
          ))}
        </Grid>

        {data.ready_in_under_an_hour == 0 ? (
          isStockAvailable?.stock == 0 ? (
            <Styles.Feature>
              <Icon name="check" />
              <Trans
                i18nKey={'bm/instance/create.step1_string13'}
                components={{ highlight: <HighlightBackground isError /> }}
              />
            </Styles.Feature>
          ) : data?.show_ready_time_banner == 1 ? (
            <Styles.Feature>
              <Icon name="check" />

              <Trans i18nKey={'bm/instance/create.step1_string14'} />
            </Styles.Feature>
          ) : null
        ) : data.ready_in_under_an_hour == 1 ? (
          isStockAvailable?.stock == 0 ? (
            <Styles.Feature>
              <Icon name="check" />
              <Trans
                i18nKey={'bm/instance/create.step1_string13'}
                components={{ highlight: <HighlightBackground isError /> }}
              />
            </Styles.Feature>
          ) : data?.show_ready_time_banner == 1 ? (
            <Styles.Feature>
              <Icon name="check" />
              <Trans
                i18nKey={'bm/instance/create.step1_string15'}
                components={{ highlight: <HighlightBackground /> }}
              />
            </Styles.Feature>
          ) : null
        ) : null}

        {data.extra_hardware_megaraid == 0 && data.extra_hardware == 1 ? (
          <div>
            <br />
            <Styles.Extra>{t('bm/instance/create.step1_string16')}</Styles.Extra>
            {extraFeaturesBM.map((feature) => (
              <Styles.Feature key={feature.id}>
                <Icon name="check" />
                {filterMemoryType(data[feature.dataKey2], feature.lanRef) == 'TB' ? (
                  <Trans
                    i18nKey={'bm/instance/create.' + feature.lang}
                    values={{
                      count: data[feature.dataKey],
                      counts: filterDataKey2(data[feature.dataKey2], feature.lanRef),
                      count2: filterCount2(data[feature.dataKey3], feature.lanRef),
                      count3: filterMemoryType(data[feature.dataKey2], feature.lanRef),
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey={'bm/instance/create.' + feature.lang}
                    values={{
                      count: data[feature.dataKey],
                      counts: filterDataKey2(data[feature.dataKey2], feature.lanRef),
                      count2: filterCount2(data[feature.dataKey3], feature.lanRef),
                      count3: filterMemoryType(data[feature.dataKey2], feature.lanRef),
                    }}
                  />
                )}
              </Styles.Feature>
            ))}
          </div>
        ) : data.extra_hardware_megaraid == 1 && data.extra_hardware == 1 ? (
          <div>
            <br />
            <Styles.Extra>{t('bm/instance/create.step1_string16')}</Styles.Extra>
            {extraMegaraidFeaturesBM.map((feature) => (
              <Styles.Feature key={feature.id}>
                <Icon name="check" />
                <Trans i18nKey={'bm/instance/create.' + feature.lanRef} />
              </Styles.Feature>
            ))}
          </div>
        ) : null}
      </Styles.Features>

      {selected && (
        <Styles.SelectedTag>
          <Icon name="check" />
          {t('bm/instance/create.step1_string17')}
        </Styles.SelectedTag>
      )}
    </Styles.ServerSizeCardSC>
  );
};

export default ServerSizeCard;
