import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import * as Styles from './ServerSizeCard.style';
import _get from 'lodash/get';
import { GreyOut } from 'pages/member/new-vm/new-vm-step-3/NewVMStep3.style';
import { createFeatures } from '../constants';
import { Highlight } from 'components/common';

export default function ServerSizeCard(props) {
  const {
    data,
    selected = false,
    onClick,
    formikProp: { values },
    vsTemplate,
    greyOutCondition,
    watermark,
    selectedText,
    userAccount,
  } = props;
  const { t } = useTranslation();
  const isSale = Number(data.price_monthly_usd) - Number(data.nonsale_price_monthly_usd);

  const findTemplate = _get(vsTemplate, 'vs_saved_templates', []).find((item) => item.id == values.template);
  const { size_in_gb } = findTemplate || {};

  return (
    <div style={{ position: 'relative' }}>
      <GreyOut
        isGrey={
          +size_in_gb > +data?.disk_gb ||
          greyOutCondition ||
          Number(data.memory_mb) < Number(values.image_object?.min_ram_mb) ||
          Number(data.disk_gb) < Number(values.image_object?.min_disk_gb)
        }
      >
        <Styles.ServerSizeCardSC data-testid="server-size-card" selected={selected} onClick={() => onClick(data)}>
          <Styles.MobileTag showTag={Number(data.mostpopular) || isSale}>
            {Number(data.mostpopular)
              ? t('vs/instance/create.step1_string6')
              : isSale
              ? t('vs/instance/create.step1_string7')
              : null}
          </Styles.MobileTag>
          <Styles.Tag showTag={Number(data.mostpopular) || isSale}>
            {Number(data.mostpopular)
              ? t('vs/instance/create.step1_string6')
              : isSale
              ? t('vs/instance/create.step1_string7')
              : null}
            {isSale ? (
              <Styles.ActualPrice>
                <Trans
                  i18nKey="vs/instance/create.step1_string13"
                  values={{
                    currency_symbol: userAccount.account_currency_symbol,
                    price: data.nonsale_price_monthly_usd,
                  }}
                />
              </Styles.ActualPrice>
            ) : null}
          </Styles.Tag>

          <Styles.FlexCon>
            <Styles.NameContainer>
              <Styles.Name>{data.id.toUpperCase()}</Styles.Name>
            </Styles.NameContainer>
            <Styles.Price>
              <Trans
                i18nKey="vs/instance/create.step1_string8"
                values={{
                  monthly_price: Math.round(data.price_monthly_usd),
                  hourly_price: data.price_hourly_usd,
                  currency: userAccount?.account_currency,
                  currency_symbol: userAccount?.account_currency_symbol,
                }}
                components={{
                  price: <Styles.PriceUnit />,
                  monthly: <Styles.PerMonth />,
                  currency: <Styles.Currency />,
                  hourly: <Styles.PerHour />,
                }}
              />
            </Styles.Price>
          </Styles.FlexCon>

          <Styles.Features>
            {createFeatures.map(
              (feature) =>
                ((feature.lanRef == 'ddos_protection' && data[feature.dataKey] == 1) ||
                  (feature.lanRef != 'ddos_protection' && feature.lanRef != 'additional_bandwidth_gb_month')) && (
                  <Styles.Feature key={feature.id}>
                    <Icon name="check" />
                    <Trans
                      i18nKey={
                        feature.lanRef == 'cpu' && data.vcpugen == 'xeongold'
                          ? data[feature.dataKey] > 1
                            ? 'vs/instance/create.step1_string16'
                            : 'vs/instance/create.step1_string15'
                          : feature.lanRef == 'cpu' && data.vcpugen == 'xeone5v4'
                          ? data[feature.dataKey] > 1
                            ? 'vs/instance/create.step1_string18'
                            : 'vs/instance/create.step1_string17'
                          : data[feature.dataKey] > 1
                          ? feature.lang_plural
                          : feature.lang
                      }
                      values={{ count: data[feature.dataKey] }}
                      components={{ highlight: <Highlight /> }}
                    />
                  </Styles.Feature>
                ),
            )}
          </Styles.Features>

          {selected && (
            <Styles.SelectedTag>
              <Icon name="check" />
              {t(selectedText || 'vs/instance/create.step1_string14')}
            </Styles.SelectedTag>
          )}
          {selected && (
            <Styles.SelectedMobileTag>
              <Icon name="check" />
            </Styles.SelectedMobileTag>
          )}
          {watermark && (
            <Styles.WaterMark>
              <div className="button-type p-3">{t(watermark)}</div>
            </Styles.WaterMark>
          )}
        </Styles.ServerSizeCardSC>
      </GreyOut>
    </div>
  );
}
