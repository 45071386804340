import React, { useMemo, useState } from 'react';
import * as Styles from '../Checkout.style';
import { Trans, useTranslation } from 'react-i18next';
import { H5, H6, P } from 'components/reusable-css';
import { useDispatch, useSelector } from 'react-redux';
import { removeBM_CART, updateBM_CART } from 'store/actions/bm-shopping-cart';
import { getOSImage } from '../../util';
import Heading from 'components/Heading/Heading';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { navLinks } from 'utils/nav-links-util';
import Select from 'components/select';
import { getLocalStorageValues } from 'constants/local-storage';

const termData = [
  { text: '1 month', value: 1 },
  { text: '12 months', value: 12 },
  { text: '24 months', value: 24 },
  { text: '36 months', value: 36 },
];

const CartSummary = () => {
  const { promoBmCode } = getLocalStorageValues();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [term, setTerm] = useState(1);
  const cartBm = useSelector((state) => state.bmCart.bm_deploy);
  const handleRemoveCart = (index) => dispatch(removeBM_CART(index));

  const handleTerm = (term, index) => {
    setTerm(term);
    dispatch(updateBM_CART({ index, term }));
  };

  const totalPrice = useMemo(() => {
    return cartBm.reduce((acc, item) => {
      if (item.term == 1) {
        return acc + parseFloat(item.price);
      } else if (item.term == 12) {
        return acc + parseFloat(item.yearly_price);
      } else if (item.term == 24) {
        return acc + parseFloat(item.bi_yearly_price);
      } else {
        return acc + parseFloat(item.tri_yearly_price);
      }
    }, 0);
  }, [term, cartBm]);

  const handleArray = (values) => {
    let array = termData;
    if (values?.price_monthly_usd == '' || values?.price_monthly_usd == 0) {
      array = array.filter((item) => item.value != 1);
    }
    if (values?.price_monthly_usd == '' || values?.price_monthly_usd == 0) {
      array = array.filter((item) => item.value != 12);
    }
    if (values?.price_biennial_usd == '' || values?.price_biennial_usd == 0) {
      array = array.filter((item) => item.value != 24);
    }
    if (values?.price_triennial_usd == '' || values?.price_triennial_usd == 0) {
      array = array.filter((item) => item.value != 36);
    }
    return array;
  };

  if (cartBm.length == 0) {
    localStorage.removeItem('remember_bm_cart_login');
    history.replace(navLinks.public_checkout_bm.link + promoBmCode);
  }

  return (
    <Styles.CartSummaryContainer>
      <Heading name="public/checkout.string1">
        {cartBm.length > 0 ? (
          <Styles.CartRow>
            <Styles.CartTable>
              <Styles.CartTableHeading>
                <H6 style={{ width: '24%' }}>{t('public/checkout.string2')}</H6>
                <H6 style={{ width: '22%', padding: '0 1em' }}>{t('public/checkout.string3')}</H6>
                <H6 style={{ width: '22%', textAlign: 'right', padding: '0 1em' }}>{t('public/checkout.string4')}</H6>
                <H6 style={{ width: '27%', textAlign: 'right', padding: '0 1em' }}>{t('public/checkout.string28')}</H6>
                <H6 style={{ width: '5%', padding: '0 1em' }} />
              </Styles.CartTableHeading>
              <Styles.CartTableContent>
                {cartBm.map((data, index) => (
                  <Styles.CartTableRow key={index}>
                    <div className="single_row">
                      <div style={{ width: '24%' }}>
                        <H6 className="bold text-uppercase">{data.selected_size.cpu_model}</H6>
                        <H6 className="text-capitalize">
                          {data?.selected_region?.city}, {data?.selected_region?.state}
                        </H6>
                      </div>
                      <div style={{ width: '22%', padding: '0 1em', display: 'flex', alignItems: 'center' }}>
                        {data?.app == '' ? (
                          <>
                            <img src={getOSImage(data?.selected_image?.family)} width={25} height={25} alt="OS Image" />
                            <H6 className="ml-3">{data?.selected_image?.name}</H6>
                          </>
                        ) : (
                          <>
                            <img src={getOSImage('almalinux')} width={25} height={25} alt="OS Image" />
                            <H6 className="ml-3">{t('AlmaLinux + cPanel')}</H6>
                          </>
                        )}
                      </div>
                      <H6 className="bold" style={{ width: '22%', textAlign: 'right', padding: '0 1em' }}>
                        <Trans
                          i18nKey={'public/checkout.string5'}
                          values={{
                            price:
                              data.term == 1
                                ? data.price.toFixed(2)
                                : data.term == 12
                                ? data.yearly_price.toFixed(2)
                                : data.term == 24
                                ? data.bi_yearly_price.toFixed(2)
                                : data.tri_yearly_price.toFixed(2),
                            currency: 'USD',
                            currency_symbol: '$',
                          }}
                          components={{
                            span: <span style={{ fontSize: '12px', fontWeight: 'normal' }} />,
                          }}
                        />
                      </H6>
                      <div style={{ width: '27%', textAlign: 'right', padding: '0 1em' }}>
                        <Select
                          value={data.term}
                          options={handleArray(data.selected_size)}
                          onChange={(e) => handleTerm(e.target.value, index)}
                          styling={{ width: '7.2em', marginTop: 0, height: '2.2em', padding: '5px' }}
                        />
                      </div>
                      <Styles.RemoveCart onClick={() => handleRemoveCart(index)}>
                        <Icon name="trash alternate outline" />
                      </Styles.RemoveCart>
                    </div>
                    <div style={{ marginTop: '.5em', marginLeft: '24%', padding: '0 1em' }}>
                      {data?.selected_image?.name && (
                        <Styles.CustomServerList>
                          <Icon name="circle" />
                          <P>
                            {t(`public/checkout/bm.string14`, {
                              name: data?.selected_image?.name,
                              monthly_price: Number(data?.selected_image?.price_monthly_usd).toFixed(2),
                            })}
                          </P>
                        </Styles.CustomServerList>
                      )}
                      {data?.customServerList?.map((item, i) => (
                        <Styles.CustomServerList key={i}>
                          <Icon name="circle" />
                          <P>{t(`public/checkout/bm.${item.name}`, { monthly_price: item.price })}</P>
                        </Styles.CustomServerList>
                      ))}
                    </div>
                  </Styles.CartTableRow>
                ))}
              </Styles.CartTableContent>
              <Styles.CartTableContentMobile>
                {cartBm.map((data, index) => (
                  <Styles.CartTableRow key={index}>
                    <div className="single_row_mobile">
                      <H6 className="bold text-uppercase">{data.selected_size.cpu_model}</H6>
                      <H6 className="bold" style={{ textAlign: 'right' }}>
                        <Trans
                          i18nKey={'public/checkout.string5'}
                          values={{
                            price:
                              data.term == 1
                                ? data.price.toFixed(2)
                                : data.term == 12
                                ? data.yearly_price.toFixed(2)
                                : data.term == 24
                                ? data.bi_yearly_price.toFixed(2)
                                : data.tri_yearly_price.toFixed(2),
                            currency: 'USD',
                            currency_symbol: '$',
                          }}
                          components={{
                            span: <span style={{ fontSize: '12px', fontWeight: 'normal' }} />,
                          }}
                        />
                      </H6>
                    </div>
                    <div className="single_row_mobile">
                      <H6 className="text-capitalize" style={{ padding: '0.5em 0' }}>
                        {data?.selected_region?.city}, {data?.selected_region?.state}
                      </H6>
                      {data.backup == 'plan0' ? (
                        <H6 style={{ textAlign: 'right', padding: '.5em 0' }}>
                          {t('public/checkout.string51')}: {t('public/checkout.string50')}
                        </H6>
                      ) : (
                        <H6 style={{ textAlign: 'right' }}>
                          {t('public/checkout.string51')}: {t('public/checkout.string49')}
                        </H6>
                      )}
                    </div>
                    <div className="single_row_mobile">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {data?.app == '' ? (
                          <>
                            <img src={getOSImage(data?.selected_image?.family)} width={25} height={25} alt="OS Image" />
                            <H6 className="ml-1">{data?.selected_image?.name}</H6>
                          </>
                        ) : (
                          <>
                            <img src={getOSImage('almalinux')} width={25} height={25} alt="OS Image" />
                            <H6 className="ml-1">{t('AlmaLinux + cPanel')}</H6>
                          </>
                        )}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ textAlign: 'right', paddingRight: '1em' }}>
                          <Select
                            value={data.term}
                            options={handleArray(data.selected_size)}
                            onChange={(e) => handleTerm(e.target.value, index)}
                            styling={{ width: '7em', marginTop: 0, height: '2.2em', padding: '5px' }}
                          />
                        </div>
                        <Styles.RemoveCart onClick={() => handleRemoveCart(index)}>
                          <Icon name="trash alternate outline" />
                        </Styles.RemoveCart>
                      </div>
                    </div>
                    <div style={{ marginTop: '.5em' }}>
                      {data?.selected_image?.name && (
                        <Styles.CustomServerList>
                          <Icon name="circle" />
                          <P>
                            {t(`public/checkout/bm.string14`, {
                              name: data?.selected_image?.name,
                              monthly_price: Number(data?.selected_image?.price_monthly_usd).toFixed(2),
                            })}
                          </P>
                        </Styles.CustomServerList>
                      )}
                      {data?.customServerList?.map((item, i) => (
                        <Styles.CustomServerList key={i}>
                          <Icon name="circle" />
                          <P>{t(`public/checkout/bm.${item.name}`, { monthly_price: item.price })}</P>
                        </Styles.CustomServerList>
                      ))}
                    </div>
                  </Styles.CartTableRow>
                ))}
              </Styles.CartTableContentMobile>
            </Styles.CartTable>
            <div>
              <Styles.PriceTable>
                <Styles.TotalPriceTable>
                  <H5>
                    {t(`public/checkout.string29`, {
                      price: totalPrice.toFixed(2),
                      currency: 'USD',
                      currency_symbol: '$',
                    })}
                  </H5>
                </Styles.TotalPriceTable>
              </Styles.PriceTable>
            </div>
          </Styles.CartRow>
        ) : (
          <H6 className="d-flex justify-content-center py-5">No record Found...</H6>
        )}
      </Heading>
    </Styles.CartSummaryContainer>
  );
};

export default CartSummary;
