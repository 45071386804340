import React from 'react';
import * as Styles from './BmCartStep2.style';
import ErrorMessage from 'components/error-message';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';
import ServerContainer from 'pages/shoping-cart/component/ServerContainer';

const BmCartStep2 = (props) => {
  const { serverSizes, formikProp } = props;
  const { errors, touched, values } = formikProp;
  const { t } = useTranslation();

  const checkIsServerAvailable = (serverName) => {
    let value = serverSizes?.bm_sizes?.some(
      (serverSize) =>
        serverSize.active && serverSize.cpugen == serverName && serverSize?.availableregions?.includes(values.region),
    );
    return value;
  };

  return (
    <Styles.NewVMStep2SC id="ST2">
      <Heading name="public/checkout/bm.step2_string1">
        <StepDescription />
        {serverSizes?.bm_sizes.length == 0 ? (
          <P style={{ marginBottom: '5em' }}>{t('public/checkout/bm.step2_string2')}</P>
        ) : (
          <>
            {checkIsServerAvailable('xeone5v4') && (
              <ServerContainer
                serverType={'xeone5v4'}
                serverSizes={serverSizes}
                heading={'public/checkout/bm.step2_string3'}
                formikProp={formikProp}
              />
            )}
            {checkIsServerAvailable('xeongold') && (
              <ServerContainer
                serverType={'xeongold'}
                serverSizes={serverSizes}
                heading={'public/checkout/bm.step2_string4'}
                formikProp={formikProp}
              />
            )}
            {checkIsServerAvailable('xeone5v4raid') && (
              <ServerContainer
                serverType={'xeone5v4raid'}
                serverSizes={serverSizes}
                heading={'public/checkout/bm.step2_string7'}
                formikProp={formikProp}
              />
            )}
          </>
        )}
        {errors.size && touched.size && <ErrorMessage />}
      </Heading>
    </Styles.NewVMStep2SC>
  );
};
export default BmCartStep2;
